<template>
  <v-container>
    <v-row class="d-flex align-center justify-center header">
      <v-col cols="auto">
        <h5 class="header__title_bold">Your Orders</h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          style="cursor: pointer;"
          :headers="headers"
          :items="items"
          :loading="api.loading"
          @click:row="rowClick"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Http from '@/api/Http';

export default {
  name: 'AccountOrders',
  components: {},
  data() {
    return {
      name: '',
      form: {},
      api: Http.use('data'),
      notify: false,
      notifyMessage: '',
      items: [],
      headers: [
        { text: 'Order Placed', align: 'start', value: 'created', },
        { text: 'Total', value: 'amountTotal' },
        { text: 'Status', value: 'status' },
        { text: 'Order #', value: '_id' },
      ],
    }
  },
  created() {
    this.index();
  },
  methods: {
    rowClick(item) {
      this.$router.push('/account/orders/' + item._id);
    },
    index() {
      this.api.get('orders/')
          .then(orders => {
            this.items = orders.map(order => {
              order.status = this.$t(`orders.status.${order.status}`);
              order.amountTotal = `$ ${(order.amountTotal/100).toFixed(2)}`;
              order.created = (new Date(order.created)).toLocaleDateString();
              return order;
            });
          });
    }
  }
}
</script>
<style scoped>
.header {
  color: var(--v-secondary-base);
  text-align: center;
  line-height: 100%;
}
</style>
